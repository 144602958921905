@import url("https://use.typekit.net/dba3uuj.css");
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 5px;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #FF6700;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #d95800;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

/*!
 * Bootstrap Grid v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

body {
  background-color: #FFF;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

/*
* "Disable" animations for people who have notified the system that they prefer an interface 
* that removes or replaces the types of motion-based animation that trigger discomfort for 
* those with vestibular motion disorders.
* See: https://css-tricks.com/revisiting-prefers-reduced-motion-the-reduced-motion-media-query/
*/
@media (prefers-reduced-motion: reduce), (update: slow) {
  *:not(.safe-animation),
  *:not(.safe-animation)::before,
  *:not(.safe-animation)::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    animation-delay: 0.01ms !important;
    transition-duration: 0s !important;
    transition-delay: 0 !important;
  }
}

/**
 * Basic styles for links
 */
button:focus:not(:focus-visible) {
  outline: 0;
}

a {
  color: #FF6700;
  text-decoration: none;
}

a:active, a:focus, a:hover {
  color: #d95800;
  text-decoration: underline;
}

.link--default {
  color: #000faa;
}

.link--default:active, .link--default:focus, .link--default:hover {
  color: #000d91;
  text-decoration: none;
}

strong {
  font-weight: 600;
}

/* Safari hack css*/
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .newsSearch__submit {
      position: absolute;
      top: 9px;
      right: 8px;
    }
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  /* Safari only override */
  .newsSearch__submit {
    position: absolute;
    top: 7px;
    right: 8px;
  }
}

/* IE hack */
@media all and (-ms-high-contrast: none) {
  .button {
    height: 40px;
    line-height: 24px !important;
  }
  .navMenu__logo svg {
    width: 90px;
    height: 50px;
  }
  .memberCard__discription {
    display: -ms-flexbox;
    width: 100%;
  }
  .controls .prev svg {
    top: 2px !important;
  }
  .controls .next svg {
    top: 2px !important;
  }
  .webinarItem {
    display: inline !important;
  }
  .webinarItem__image {
    width: 100%;
    height: auto;
    max-height: 100%;
  }
  .webinarItem__container {
    display: inline !important;
  }
  .webinarItem__demand,
  .webinarItem__demandIcon {
    background-color: #fff !important;
    display: block;
  }
  .webinarItem__demandIcon {
    background-color: #fff;
    position: relative;
  }
  .webinarPage__grid svg {
    height: 30px;
  }
  .webinarPage__list svg {
    height: 30px;
  }
  .newsSearch__submit {
    top: 8px !important;
    right: 17px !important;
  }
}

.hideContent {
  position: absolute;
  height: 1px;
  width: 1px;
  border: 0;
  clip: rect(0 0 0 0);
  margin: -1px;
  overflow: hidden;
  padding: 0;
  z-index: -1;
}

/**
 * Typography.
 */
body {
  color: #202122;
  font-family: "myriad-pro", "Arial", sans-serif;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
}

@media screen and (min-width: 576px) {
  body {
    font-size: 1.0625rem;
  }
}

@media screen and (min-width: 992px) {
  body {
    font-size: 1.125rem;
  }
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  color: #0058ff;
  font-family: inherit;
  font-weight: 500;
  font-style: italic;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h1, .h1 {
  font-size: 1.75rem;
}

h2, .h2 {
  font-size: 1.5rem;
}

h3, .h3 {
  font-size: 1.25rem;
}

h4, .h4 {
  font-size: 1.125rem;
}

h5, .h5 {
  font-size: 1rem;
}

h6, .h6 {
  font-size: 0.875rem;
}

p, ol:not([class]), ul:not([class]), td {
  font-family: "myriad-pro", sans-serif;
  font-weight: 400;
}

.obf-text {
  height: 1em;
  background-color: currentColor;
  border-radius: 1em;
  display: inline-block;
  filter: grayscale(1);
  opacity: .25;
}

.blur-text {
  position: relative;
  filter: blur(4px);
  margin-bottom: 0;
}

.blur-text::after {
  position: absolute;
  top: -8px;
  right: -8px;
  bottom: -8px;
  left: -8px;
  background: linear-gradient(rgba(255, 255, 255, 0), #FFF);
  content: '';
}

/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */
.hide-text {
  overflow: hidden;
  padding: 0;
  /* 1 */
  text-indent: 101%;
  white-space: nowrap;
}

/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.block-title {
  padding: 36px 0 20px;
}

.page-background {
  background: #FFF;
  background-repeat: no-repeat;
  background-position: top center, bottom center;
  background-size: auto;
  padding-bottom: 64px;
}

.col,
[class*="col-"] {
  padding-right: 8px;
  padding-left: 8px;
}

.row {
  margin-right: -8px;
  margin-left: -8px;
}

ol.row {
  list-style: none;
  margin-bottom: 0;
  padding: 0;
}

.container-fluid {
  max-width: 1140px;
  padding-right: 16px;
  padding-left: 16px;
}

@media (max-width: 991px) {
  .container-fluid--content {
    max-width: 640px;
  }
}

.w-100 {
  width: 100%;
}

.w-75 {
  width: 75%;
}

.w-65 {
  width: 65%;
}

.w-30 {
  width: 30%;
}

.mw-320 {
  max-width: 320px;
}

::-ms-reveal {
  display: none;
}

::-ms-clear {
  display: none;
}

.skiplink {
  position: absolute;
  height: 0;
  width: 0;
  background-color: #fff;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.24);
  clip: rect(1px, 1px, 1px, 1px);
  color: #202122;
  font-weight: 600;
  overflow: hidden;
  padding: 1rem 2rem;
  white-space: nowrap;
}

.skiplink:focus {
  left: 16px;
  top: 1rem;
  height: auto;
  width: auto;
  clip: auto;
  outline: 0;
  overflow: auto;
  white-space: normal;
  z-index: 2000;
}

.pagination {
  margin: 48px 0 32px 0;
  display: flex;
}

.pagination__list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
}

.pagination__listItemContainer {
  display: flex;
  margin: auto;
  padding-left: 0;
  justify-content: center;
}

.pagination__listItem {
  display: flex;
  text-align: center;
  align-items: center;
}

.pagination__listBackButtonContainer, .pagination__listForwardButtonContainer {
  display: flex;
  flex: 1 1 0%;
  padding-left: 0;
  margin: 0;
}

.pagination__listBackButtonContainer {
  margin-right: 4px;
  justify-content: flex-end;
}

.pagination__listForwardButtonContainer {
  margin-left: 4px;
}

.pagination__listButton {
  display: flex;
}

.pagination__listButton:not(:last-child) {
  margin-right: 8px;
}

@media screen and (min-width: 576px) {
  .pagination__listButton:not(:last-child) {
    margin-right: 16px;
  }
}

.pagination__link {
  font-size: 1rem;
  padding: 0;
  width: 48px;
  height: 48px;
  line-height: 48px;
  text-decoration: none;
  color: #000faa;
  transition: all 0.15s ease-in-out;
  position: relative;
  z-index: 1;
}

.pagination__link::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 32px;
  height: 32px;
  background-color: transparent;
  z-index: -1;
  left: 8px;
  top: 8px;
  border-radius: 100%;
  border: 1px solid transparent;
  transition: all 0.15s ease-in-out;
}

.pagination__link:hover, .pagination__link:active, .pagination__link.active {
  color: #FFF;
  text-decoration: none;
}

.pagination__link:hover:before, .pagination__link:active:before, .pagination__link.active:before {
  background-color: #000faa;
  border: 1px solid #000faa;
}

.pagination__link:active, .pagination__link.active {
  cursor: default;
  outline: none;
}

.pagination__button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  height: 32px;
  background-color: transparent;
  border: 1px solid #000faa;
  border-radius: 50px;
  color: #000faa;
  font-size: 1rem;
  line-height: calc(32px - (2 * 1px));
  padding: 0 8px;
  text-decoration: none;
  text-transform: lowercase;
  transition: all 0.15s ease-in-out;
  text-indent: -9999px;
}

@media screen and (min-width: 576px) {
  .pagination__button {
    text-indent: inherit;
    padding: 0 16px;
  }
}

.pagination__button:hover, .pagination__button:active, .pagination__button.active, .pagination__button:focus {
  background-color: #000faa;
  border: 1px solid #000faa;
  color: #FFF;
  text-decoration: none;
}

.pagination__button:hover svg, .pagination__button:active svg, .pagination__button.active svg, .pagination__button:focus svg {
  fill: #FFF;
}

.pagination__button svg {
  width: 8px;
  height: 16px;
  display: inline-block;
  fill: #000faa;
  transition: all 0.15s ease-in-out;
}

.pagination__iconRight {
  margin-left: 2px;
}

@media screen and (min-width: 576px) {
  .pagination__iconRight {
    margin-left: 8px;
  }
}

.pagination__iconLeft {
  margin-right: 2px;
  transform: rotate(180deg);
}

@media screen and (min-width: 576px) {
  .pagination__iconLeft {
    margin-right: 8px;
  }
}

.button {
  position: relative;
  border: 1px solid transparent;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  line-height: 20px;
  padding: calc(0.625rem - 1px) 1rem;
  text-decoration: none;
  transition: all 0.15s ease-in-out;
  user-select: none;
  vertical-align: middle;
}

.button:hover, .button:focus {
  outline: none;
  text-decoration: none;
}

.button.disabled, .button:disabled {
  opacity: .65;
  pointer-events: none;
}

.button svg {
  width: 16px;
  height: 16px;
  display: inline-block;
  fill: #FFF;
  transition: all 0.15s ease-in-out;
}

.button__iconRight {
  margin-left: 8px;
}

.button__iconLeft {
  margin-right: 8px;
  transform: rotate(180deg);
}

.button--primary {
  color: #000faa;
  background-color: #FFF;
  border-color: #000faa;
}

.button--primary svg {
  fill: #000faa;
}

.button--primary:hover, .button--primary:focus {
  color: #427ac9;
  background-color: #FFF;
  border-color: #427ac9;
}

.button--primary:hover svg, .button--primary:focus svg {
  fill: #427ac9;
}

.button--secondary {
  color: #FFF;
  background-color: #FF6700;
  border-color: #FF6700;
}

.button--secondary svg {
  fill: #FFF;
}

.button--secondary:hover, .button--secondary:focus {
  color: #FFF;
  background-color: #FE8000;
  border-color: #FE8000;
}

.button--secondary:hover svg, .button--secondary:focus svg {
  fill: #FFF;
}

.button--tertiary {
  color: #FFF;
  background-color: #0058ff;
  border-color: #0058ff;
}

.button--tertiary svg {
  fill: #FFF;
}

.button--tertiary:hover, .button--tertiary:focus {
  color: #FFF;
  background-color: #004bd9;
  border-color: #004bd9;
}

.button--tertiary:hover svg, .button--tertiary:focus svg {
  fill: #FFF;
}

.button--light {
  background-color: #FFF;
  border-color: #009;
  color: #009;
}

.button--light svg {
  fill: #009;
}

.button--light:hover, .button--light:focus {
  background-color: #FFF;
  border-color: #427ac9;
  color: #427ac9;
}

.button--light:hover svg, .button--light:focus svg {
  fill: #427ac9;
}

.button--transparent {
  background-color: transparent;
  border-color: transparent;
  color: #FFF;
}

.button--transparent:hover, .button--transparent:focus {
  background-color: transparent;
  border-color: transparent;
  color: #FF6700;
}

.button--default {
  background-color: #000faa;
  color: #FFF;
  border-color: #000faa;
}

.button--default svg {
  fill: #FFF;
}

.button--default:hover, .button--default:focus {
  color: #FFF;
  background-color: #000d91;
  border-color: #000d91;
}

.button--default:hover svg, .button--default:focus svg {
  fill: #FFF;
}

.button--outlineLight {
  color: #FFF;
  background-color: transparent;
  border-color: #FFF;
}

.button--outlineLight svg {
  fill: #FFF;
}

.button--outlineLight:hover, .button--outlineLight:focus {
  color: #FFF;
  background-color: #0058ff;
  border-color: #0058ff;
}

.button--outlineLight:hover svg, .button--outlineLight:focus svg {
  fill: #FFF;
}

.button--outlinePrimary {
  color: #000faa;
  background-color: transparent;
  border-color: #000faa;
}

.button--outlinePrimary svg {
  fill: #000faa;
}

.button--outlinePrimary:hover, .button--outlinePrimary:focus {
  color: #FFF;
  background-color: #000d91;
  border-color: #000faa;
}

.button--outlinePrimary:hover svg, .button--outlinePrimary:focus svg {
  fill: #FFF;
}

.button--outlineDefault {
  color: #000faa;
  background-color: #FFF;
  border-color: #000faa;
}

.button--outlineDefault svg {
  fill: #000faa;
}

.button--outlineDefault:hover, .button--outlineDefault:focus {
  color: #FFF;
  background-color: #000d91;
  border-color: #000d91;
}

.button--outlineDefault:hover svg, .button--outlineDefault:focus svg {
  fill: #FFF;
}

.button--outlineWarning {
  color: #e03100;
  background-color: #FFF;
  border-color: #e03100;
}

.button--outlineWarning svg {
  fill: #e03100;
}

.button--outlineWarning:hover, .button--outlineWarning:focus {
  color: #FFF;
  background-color: #be2a00;
  border-color: #be2a00;
}

.button--outlineWarning:hover svg, .button--outlineWarning:focus svg {
  fill: #FFF;
}

.button--lg {
  width: 100%;
  max-width: 350px;
}

.button--md {
  width: 100%;
  max-width: 250px;
}

.button--readMore {
  color: #000faa;
  padding-left: 0;
}

.button--readMore svg {
  fill: #000faa;
}

.button--readMore:hover {
  color: #0058ff;
}

.button--readMore:hover svg {
  fill: #0058ff;
}

.button--readMoreLight {
  color: rgba(255, 255, 255, 0.8);
  padding-left: 0;
}

.button--readMoreLight svg {
  fill: rgba(255, 255, 255, 0.8);
}

.button--readMoreLight:hover {
  color: #FFF;
}

.button--readMoreLight:hover svg {
  fill: #FFF;
}

.button--prev {
  border: 0;
  color: #202122;
  padding-left: 0;
}

.button--prev svg #arrow-icon {
  fill: #202122;
}

.button--prev:hover {
  color: #202122;
}

.button--prev:hover svg #arrow-icon {
  fill: #202122;
}

.button--link {
  color: #FFF;
}

.button--link svg {
  fill: #FFF;
}

.button--link:hover {
  color: #FFF;
}

.button--link:hover svg {
  fill: #FFF;
}

.button--link:active {
  color: #FFF;
}

.button--tags {
  font-size: 0.8125rem;
  line-height: 1rem;
  margin-bottom: 16px;
  padding: 5px 10px;
}

.button--tags:not(:last-of-type) {
  margin-right: 16px;
}

.button--warning {
  color: #FFF;
  background-color: #e03100;
  border-color: #e03100;
}

.button--warning:hover, .button--warning:focus {
  color: #FFF;
  background-color: #be2a00;
  border-color: #be2a00;
}

.button--facebook {
  color: #FFF;
  background-color: #3b5998;
  border-color: #3b5998;
  white-space: nowrap;
}

.button--facebook svg {
  fill: #FFF;
  width: 20px;
  height: 20px;
}

.button--facebook:hover, .button--facebook:focus {
  color: #FFF;
  background-color: #324c81;
  border-color: #324c81;
}

.button--facebook:hover svg, .button--facebook:focus svg {
  fill: #FFF;
}

.button--linkedin {
  color: #FFF;
  background-color: #0077b5;
  border-color: #0077b5;
  white-space: nowrap;
}

.button--linkedin svg {
  fill: #FFF;
  width: 20px;
  height: 20px;
}

.button--linkedin:hover, .button--linkedin:focus {
  color: #FFF;
  background-color: #00659a;
  border-color: #00659a;
}

.button--linkedin:hover svg, .button--linkedin:focus svg {
  fill: #FFF;
}

.btnFlexContainer {
  width: calc(100% + 16px);
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
  text-align: center;
}

.btnFlexContainer > .button, .btnFlexContainer > form {
  margin: 0 8px 16px 8px;
}

.btnFlexContainer > .button {
  flex: 1 1 100%;
}

@media screen and (min-width: 576px) {
  .btnFlexContainer > .button {
    flex: 1 1 0%;
  }
}

.btnFlexContainer > form {
  flex: 1 1 0%;
}

.btnPrevPageContainer {
  padding: 24px 0 64px;
  text-align: center;
}

.mainNav {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 56px;
  margin-right: -16px;
  z-index: 20;
}

@media (min-width: 768px) {
  .mainNav {
    padding-right: 72px;
    margin-right: 0;
  }
}

@media (min-width: 768px) {
  .mainNav {
    padding-right: 0;
  }
}

.mainNav__logo {
  flex: 0 0 auto;
  height: 24px;
  box-sizing: content-box;
  display: inline-flex;
  align-items: center;
  margin: 0 8px 0 0;
  padding: 20px 0 20px 0;
}

@media (min-width: 768px) {
  .mainNav__logo {
    height: 28px;
    margin-right: 32px;
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

@media (min-width: 992px) {
  .mainNav__logo {
    height: 28px;
    padding-top: 32px;
    padding-bottom: 32px;
  }
}

.mainNav__logo img {
  height: 100%;
  width: auto;
  max-height: 100%;
  max-width: 100%;
  display: inline-block;
  object-fit: contain;
}

.mainNav__menu {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  background: #FFF;
  overflow-y: auto;
  padding: 56px 0 24px 0;
  transform: translate(100%, 0);
  /* autoprefixer: ignore next */
  transition: transform 500ms cubic-bezier(0.645, 0.045, 0.355, 1), height 0.01s linear;
  z-index: 1035;
}

.mainNav__menu.collapse {
  display: none;
}

.mainNav__menu.collapse.show {
  display: block;
}

.mainNav__menu.show {
  transform: translate(0, 0);
}

@media (min-width: 768px) {
  .mainNav__menu {
    position: relative;
    flex: 1 0 auto;
    width: auto;
    display: flex !important;
    background: none;
    overflow-y: visible;
    padding: 0;
    transform: none;
    transition: none;
  }
}

.mainNav__list {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
}

@media (min-width: 768px) {
  .mainNav__list {
    flex-direction: row;
  }
}

.mainNav__item {
  position: relative;
}

.mainNav__item--register {
  display: flex;
  text-align: center;
  justify-content: center;
  margin-top: 48px;
}

@media (min-width: 576px) {
  .mainNav__item--register {
    display: none;
  }
}

.mainNav__link {
  position: relative;
  color: #202122;
  display: block;
  font-size: 1.25rem;
  font-weight: 400;
  letter-spacing: 0;
  padding: 12px 16px;
  text-decoration: none;
  text-transform: none;
  transition: all 0.15s ease-in-out;
}

.mainNav__link::after {
  position: absolute;
  left: 16px;
  bottom: calc(50% - 1.25rem);
  width: calc(100% - (2 * 16px));
  height: 0;
  background-color: #FF6700;
  content: '';
  opacity: 0;
  transition: all 0.15s ease-in-out;
}

.mainNav__linkSubArrow {
  display: none;
}

@media (min-width: 768px) {
  .mainNav__linkSubArrow {
    position: absolute;
    left: calc(50% - 5px);
    bottom: calc(50% - (1rem + (2 * 5px)));
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-top-color: #202122;
    display: inline-block;
    transition: all 0.15s ease-in-out;
    z-index: 2;
  }
  .mainNav__item:hover .mainNav__linkSubArrow,
  .mainNav__item:focus .mainNav__linkSubArrow {
    border-top-color: #FF6700;
  }
}

.mainNav__link:hover, .mainNav__link:focus, .mainNav__link.active {
  color: #202122;
  text-decoration: none;
}

.mainNav__link:hover::after, .mainNav__link:focus::after, .mainNav__link.active::after {
  height: 2px;
  opacity: 1;
}

@media (min-width: 768px) {
  .mainNav__link {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 20px;
    color: #202122;
    font-size: 1rem;
    font-weight: 500;
  }
  .mainNav__link::after {
    left: 20px;
    bottom: calc(50% - 1rem);
    width: calc(100% - (2 * 20px));
    background-color: #FF6700;
  }
  .mainNav__link:hover, .mainNav__link:focus, .mainNav__link.active {
    color: #202122;
  }
  .mainNav__link:hover::after, .mainNav__link:focus::after, .mainNav__link.active::after {
    height: 2px;
  }
  .mainNav__link:hoverSubArrow, .mainNav__link:focusSubArrow {
    border-top-color: #FF6700;
  }
}

.mainNav__submenu {
  overflow: hidden;
  z-index: 1;
}

@media (min-width: 768px) {
  .mainNav__submenu {
    position: absolute;
    top: calc(50% + 1rem);
    left: 0;
    height: 0;
    min-width: 320px;
    background-color: #FFF;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.24);
    opacity: 0;
    transition: opacity 0.15s ease-in-out;
  }
  .mainNav__submenu:focus-within,
  .mainNav__item:hover .mainNav__submenu {
    height: auto;
    opacity: 1;
  }
}

.mainNav__sublist {
  list-style: none;
  margin: 0;
  padding: 0 0 8px 0;
}

@media (min-width: 768px) {
  .mainNav__sublist {
    padding-top: 8px;
  }
}

.mainNav__sublink {
  position: relative;
  color: #202122;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0;
  padding: 12px 16px;
  text-decoration: none;
}

.mainNav__sublink::after {
  position: absolute;
  left: 16px;
  bottom: 4px;
  width: 56px;
  height: 0;
  background-color: #FF6700;
  content: '';
  opacity: 0;
  transition: all 0.15s ease-in-out;
}

.mainNav__sublink:hover, .mainNav__sublink:focus, .mainNav__sublink.active {
  color: #202122;
}

.mainNav__sublink:hover::after, .mainNav__sublink:focus::after, .mainNav__sublink.active::after {
  height: 2px;
  opacity: 1;
}

@media (min-width: 768px) {
  .mainNav__sublink {
    color: #202122;
    font-size: 1rem;
    font-weight: 500;
    padding: 12px 20px;
  }
  .mainNav__sublink::after {
    left: 20px;
    background-color: #FF6700;
  }
  .mainNav__sublink:hover, .mainNav__sublink:focus, .mainNav__sublink.active {
    color: #202122;
  }
  .mainNav__sublink:hover::after, .mainNav__sublink:focus::after, .mainNav__sublink.active::after {
    height: 2px;
    opacity: 1;
  }
}

.mainNav__right {
  height: 100%;
  display: flex;
  flex: 0 1 auto;
  min-width: 0;
}

@media (min-width: 768px) {
  .mainNav__right {
    height: auto;
  }
}

.mainNav__account {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.mainNav__accountitem {
  position: relative;
  min-height: 40px;
}

.mainNav__accountitem--register {
  display: none;
}

@media (min-width: 576px) {
  .mainNav__accountitem--register {
    display: list-item;
  }
}

.mainNav__accountitem--register::after {
  position: absolute;
  top: calc(50% - .75rem);
  right: 0;
  height: 1.5rem;
  width: 1px;
  background-color: #D8D8D8;
  content: '';
}

.mainNav__accountlink {
  height: 100%;
  background: none;
  border: none;
  color: #202122;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0 8px;
  text-decoration: none;
  transition: all 0.15s ease-in-out;
}

@media (min-width: 576px) {
  .mainNav__accountlink {
    font-size: 1rem;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.mainNav__accountlink:hover, .mainNav__accountlink:focus {
  color: #000060;
  text-decoration: none;
}

.mainNav__mobile {
  width: 30px;
  height: 30px;
  display: block;
}

.mainNav__menuToggle {
  background-color: transparent;
  border: 0;
  height: 56px;
  width: 56px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 4px;
  right: 0;
  text-indent: -99em;
  transition: background-color 0.3s ease-in-out;
  z-index: 1035;
}

@media (min-width: 768px) {
  .mainNav__menuToggle {
    height: 72px;
    width: 72px;
    top: 7px;
  }
}

@media (min-width: 992px) {
  .mainNav__menuToggle {
    top: 19px;
  }
}

@media (min-width: 768px) {
  .mainNav__menuToggle {
    display: none;
  }
}

.mainNav__menuToggle:hover .mainNav__menuToggleLine, .mainNav__menuToggle:focus .mainNav__menuToggleLine {
  background-color: #202122;
}

.mainNav__menuToggleLine {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: #202122;
  border-radius: 1px;
  display: inline-block;
  height: 2px;
  left: 16px;
  opacity: 1;
  position: absolute;
  transform: scale3d(0.999, 0.999, 0.999);
  -moz-transition: opacity 0.2s ease-in-out 200ms, background-color 0.2s ease-in-out 200ms, transform 0.3s ease-in-out 200ms;
  -o-transition: opacity 0.2s ease-in-out 200ms, background-color 0.2s ease-in-out 200ms, transform 0.3s ease-in-out 200ms;
  -webkit-transition: opacity 0.2s ease-in-out 200ms, background-color 0.2s ease-in-out 200ms, transform 0.3s ease-in-out 200ms;
  transition: opacity 0.2s ease-in-out 200ms, background-color 0.2s ease-in-out 200ms, transform 0.3s ease-in-out 200ms;
  width: 24px;
}

@media (min-width: 768px) {
  .mainNav__menuToggleLine {
    border-radius: 1.5px;
    height: 3px;
    left: 19px;
    width: 34px;
  }
}

.mainNav__menuToggleLine--top {
  top: calc(50% - ((2px * 1.5) + 4px));
}

@media (min-width: 768px) {
  .mainNav__menuToggleLine--top {
    top: calc(50% - ((3px * 1.5) + 5px));
  }
}

.mainNav__menuToggleLine--middle1, .mainNav__menuToggleLine--middle2 {
  top: calc(50% - (2px / 2));
}

@media (min-width: 768px) {
  .mainNav__menuToggleLine--middle1, .mainNav__menuToggleLine--middle2 {
    top: calc(50% - (3px / 2));
  }
}

.mainNav__menuToggleLine--bottom {
  top: calc(50% + (2px / 2) + 4px);
}

@media (min-width: 768px) {
  .mainNav__menuToggleLine--bottom {
    top: calc(50% + (3px / 2) + 5px);
  }
}

.mainNav__menuToggle:not(.collapsed) {
  position: fixed;
  right: 0;
}

@media (min-width: 768px) {
  .mainNav__menuToggle:not(.collapsed) {
    right: 16px;
  }
}

.mainNav__menuToggle:not(.collapsed) .mainNav__menuToggleLine {
  background-color: #202122;
  transition-delay: 0s;
}

.mainNav__menuToggle:not(.collapsed) .mainNav__menuToggleLine:hover .mainNav__menuToggleLine, .mainNav__menuToggle:not(.collapsed) .mainNav__menuToggleLine:focus .mainNav__menuToggleLine {
  background-color: #202122;
}

.mainNav__menuToggle:not(.collapsed) .mainNav__menuToggleLine--top, .mainNav__menuToggle:not(.collapsed) .mainNav__menuToggleLine--bottom {
  opacity: 0;
}

.mainNav__menuToggle:not(.collapsed) .mainNav__menuToggleLine--middle1 {
  -moz-transform: rotate3d(0, 0, 1, 45deg) scale3d(0.999, 0.999, 0.999);
  -ms-transform: rotate3d(0, 0, 1, 45deg) scale3d(0.999, 0.999, 0.999);
  -o-transform: rotate3d(0, 0, 1, 45deg) scale3d(0.999, 0.999, 0.999);
  -webkit-transform: rotate3d(0, 0, 1, 45deg) scale3d(0.999, 0.999, 0.999);
  transform: rotate3d(0, 0, 1, 45deg) scale3d(0.999, 0.999, 0.999);
}

.mainNav__menuToggle:not(.collapsed) .mainNav__menuToggleLine--middle2 {
  -moz-transform: rotate3d(0, 0, 1, -45deg) scale3d(0.999, 0.999, 0.999);
  -ms-transform: rotate3d(0, 0, 1, -45deg) scale3d(0.999, 0.999, 0.999);
  -o-transform: rotate3d(0, 0, 1, -45deg) scale3d(0.999, 0.999, 0.999);
  -webkit-transform: rotate3d(0, 0, 1, -45deg) scale3d(0.999, 0.999, 0.999);
  transform: rotate3d(0, 0, 1, -45deg) scale3d(0.999, 0.999, 0.999);
}

.pageFooter__logo {
  background: #EFEFEF;
  padding: 32px 0;
}

.pageFooter__logoImage {
  height: 20px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
}

@media (min-width: 768px) {
  .pageFooter__logoImage {
    height: 24px;
  }
}

@media (min-width: 992px) {
  .pageFooter__logoImage {
    height: 28px;
  }
}

.pageFooter__subMenu {
  background-color: #f5f5f5;
}

.pageFooter__subMenuList {
  text-align: center;
  margin: 0 auto;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  justify-content: center !important;
  padding-left: 0;
  list-style: none;
}

.pageFooter__subMenuListItem {
  display: inline-block;
  padding: 0 20px;
}

.pageFooter__subMenuLink {
  color: #666;
  font-size: 1rem;
}

.pageFooter__subMenuLink:hover, .pageFooter__subMenuLink:focus {
  color: #141240;
}

.pageFooter__info {
  background-color: #FFF;
  padding: 24px 0 48px 0;
}

.pageFooter__infologo {
  height: auto;
  max-width: 200px;
  display: inline-block;
  margin-top: 23px;
  max-height: 40px;
}

.pageFooter__infotext {
  color: #666;
  text-align: center;
  font-family: "myriad-pro", sans-serif;
  font-size: 0.875rem;
  margin-top: 24px;
}

.pageFooter__infotext img {
  width: 100%;
  height: auto;
  max-width: 100%;
  display: block;
}

.pageFooter__infotext p {
  font-weight: 400;
  font-size: 0.875rem;
  font-family: "myriad-pro", sans-serif;
}

.pageFooter__title {
  color: #202122;
  font-size: 0.875rem;
  font-weight: 400;
  margin-top: 1.5rem;
}

@media (min-width: 768px) {
  .pageFooter__title {
    margin-top: 0;
  }
}

.pageFooter__list {
  list-style: none;
  padding-left: 0;
  position: relative;
  column-count: initial;
  column-gap: 16px;
  padding-top: 8px;
}

@media (min-width: 576px) {
  .pageFooter__list {
    column-count: 2;
  }
}

.pageFooter__listItem {
  font-size: 1rem;
  position: relative;
  padding: 0 0 8px 16px;
}

.pageFooter__listLink {
  color: #666;
  font-weight: 500;
}

.pageFooter__listLink:hover, .pageFooter__listLink:focus, .pageFooter__listLink:active {
  color: #000060;
}

.pageFooter__listItemLine {
  display: inline-block;
  height: 16px;
  width: 3px;
  position: absolute;
  top: 3px;
  left: 0;
}

.pageFooter__powered {
  background-color: #FFF;
  padding: .25rem 0;
}

.pageFooter__powered .container-fluid {
  display: flex;
  justify-content: center;
}

.pageFooter__poweredLink {
  display: inline-flex;
  align-items: center;
  color: #676767;
  font-size: .8125rem;
  padding: .25rem 0;
  text-decoration: none;
  transition: color 0.15s ease-in-out;
}

@media screen and (min-width: 992px) {
  .pageFooter__poweredLink {
    font-size: .875rem;
  }
}

.pageFooter__poweredLink:focus, .pageFooter__poweredLink:hover {
  color: #008CFF;
  text-decoration: none;
}

.pageFooter__poweredLogo {
  width: 1.75rem;
  height: auto;
  margin-right: .75rem;
}

@media screen and (min-width: 992px) {
  .pageFooter__poweredLogo {
    width: 2rem;
  }
}

.memberCard {
  position: relative;
  background-color: #FFF;
  padding: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start;
  text-decoration: none;
  transition: all 0.15s ease-in-out;
}

.memberCard:focus .button--readMore, .memberCard:hover .button--readMore {
  color: #0058ff;
}

.memberCard:focus .button--readMore svg, .memberCard:hover .button--readMore svg {
  fill: #0058ff;
}

.memberCard--small {
  padding: 0;
  margin-bottom: 16px;
  flex-direction: row;
  align-items: center;
}

@media screen and (min-width: 576px) {
  .memberCard--small {
    margin-bottom: 0;
  }
}

.memberCard--noBackground {
  background: transparent;
}

.memberCard--boxShadow {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
}

.memberCard--webinar {
  padding: 16px 0;
}

.memberCard__image {
  width: 64px;
  height: 64px;
  margin-right: 16px;
  padding: 0;
  border-radius: 50%;
  overflow: hidden;
  flex: 0 0 auto;
}

.memberCard__image--small {
  width: 40px;
  height: 40px;
  margin-right: 8px;
}

.webinarItem--obf .memberCard__image--small {
  background-color: #EFEFEF;
}

.memberCard__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.memberCard__name {
  min-width: 0;
  flex: 0 1 auto;
  color: #202122;
}

.webinarItem--obf .memberCard__name {
  flex: 1 1 0%;
}

.memberCard__title {
  font-size: 1.125rem;
  font-weight: 500;
  color: #202122;
  margin-bottom: 0;
}

.memberCard--small .memberCard__title {
  font-size: 0.875rem;
  margin-bottom: 0;
}

.memberCard__subTitle {
  font-size: 0.875rem;
  font-weight: 500;
  color: #5c5c5c;
}

.memberCard--small .memberCard__subTitle {
  font-size: 0.6875rem;
}

.memberCard__discription {
  font-size: 1rem;
  display: block;
  flex-grow: 1;
  overflow: hidden;
  color: #202122;
  margin-bottom: 8px;
}

.memberCard__discription > p {
  font-family: inherit;
  font-weight: inherit;
  margin-bottom: .5em;
}

.memberCard__discription > p:last-child {
  margin-bottom: 0;
}

.memberCard__profile {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  max-width: 100%;
  overflow: hidden;
}

.videoWrapper {
  position: relative;
  width: 90%;
}

.videoWrapper iframe {
  display: none;
}

.videoWrapper__image {
  max-width: 100%;
  width: 100%;
  height: auto;
}

.videoWrapper__button {
  position: absolute;
  z-index: 666;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border: 0;
  cursor: pointer;
  width: 60px;
  height: 60px;
  background-color: #3e8d9d;
  border-radius: 50%;
}

.videoWrapper__button:hover {
  cursor: pointer;
  margin-right: 1px;
}

.videoWrapper__button:focus {
  outline: 0;
}

.videoWrapper__button svg {
  margin-left: 5px;
  fill: #fff;
}

.videoWrapper__video {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .videoWrapper {
    width: 100%;
  }
  .videoWrapper img {
    max-width: 100%;
  }
}

.headerBanner {
  background-color: #000faa;
  padding: 40px 0;
}

.headerBanner__title {
  color: #FFF;
  margin-bottom: 0;
}

.headerBanner__subtitle {
  color: rgba(255, 255, 255, 0.75);
  display: inline-block;
  font-weight: normal;
  text-transform: uppercase;
}

.headerBanner__text {
  color: #FFF;
  font-size: 1.25rem;
  font-weight: normal;
}

.headerBanner__btnContainer {
  display: flex;
  align-items: center;
  margin-top: 24px;
}

@media (min-width: 992px) {
  .headerBanner__btnContainer {
    justify-content: flex-end;
    margin-top: 0;
  }
}

.headerBanner--profileDetail {
  padding: 32px 0;
  background-color: #000faa;
}

.headerBanner--profileDetail__title {
  margin-bottom: 0;
  font-weight: 700;
}

.homeBanner {
  position: relative;
  background-color: #FFF;
  min-height: 240px;
}

@media (min-width: 576px) {
  .homeBanner {
    min-height: 320px;
  }
}

@media (min-width: 768px) {
  .homeBanner {
    min-height: 420px;
  }
}

@media (min-width: 992px) {
  .homeBanner {
    min-height: 480px;
  }
}

.homeBanner__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.homeBanner__image > img,
.homeBanner__image > source {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: right center;
}

.homeBanner__container {
  position: relative;
  background: none;
  padding: 32px 0;
}

@media (min-width: 992px) {
  .homeBanner__content {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.homeBanner__text {
  background-color: rgba(255, 255, 255, 0.94);
  border-radius: 10px;
  margin: 0 -16px;
  padding: 16px 16px 32px;
}

.homeBanner__text > h1,
.homeBanner__text > h2 {
  color: #0058ff;
  font-size: 1.75rem;
  margin-bottom: 1.5rem;
}

@media (min-width: 768px) {
  .homeBanner__text > h1,
  .homeBanner__text > h2 {
    font-size: 2rem;
  }
}

@media (min-width: 992px) {
  .homeBanner__text > h1,
  .homeBanner__text > h2 {
    font-size: 2.25rem;
  }
}

.homeBanner__text > p {
  color: #202122;
  font-weight: normal;
  margin-bottom: .25rem;
}

.homeBanner__type {
  color: #202122;
  margin-bottom: 2rem;
}

.homeBanner__buttonContainer {
  margin-top: 24px;
}

@media (min-width: 768px) {
  .homeBanner__buttonContainer {
    margin-top: 48px;
  }
}

.upcomingWebinars {
  background-color: transparent;
  padding: 32px 0;
}

.upcomingWebinars--home {
  background-color: #f7f7f7;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .upcomingWebinars--home .webinarItem:nth-child(2):nth-last-child(1) {
    flex-basis: 100%;
    max-width: 100%;
  }
}

.upcomingWebinars--home .webinarItem:nth-child(3) {
  display: none;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .upcomingWebinars--home .webinarItem:nth-child(3) {
    display: flex;
  }
}

.upcomingWebinars__btnContainer {
  margin-top: 32px;
  text-align: center;
}

.upcomingWebinars__title {
  color: #0058ff;
  margin-bottom: 1.5rem;
}

.upcomingWebinars__description {
  margin-bottom: 20px;
  margin-bottom: 3rem;
}

.webinarOverview {
  background: #e0e0e1;
  background-repeat: no-repeat;
  background-position: top center, bottom center;
  background-size: auto;
  padding-bottom: 64px;
}

.webinarOverview__inner {
  padding: 32px 0;
  background-color: transparent;
}

.webinarOverview__gridList {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 1.125rem;
  font-weight: 300;
  margin: -16px 0 16px 0;
}

@media screen and (min-width: 768px) {
  .webinarOverview__gridList {
    margin-top: -24px;
    margin-bottom: 24px;
  }
}

@media screen and (min-width: 992px) {
  .webinarOverview__gridList {
    margin-top: -32px;
  }
}

.webinarOverview__viewMode {
  display: inline-block;
  width: 44px;
  height: 44px;
  padding: 6px;
  transition: all 0.15s ease-in-out;
}

.webinarOverview__viewMode:first-child {
  margin-left: 5px;
}

.webinarOverview__viewMode svg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #FFF;
  fill: #000faa;
  border: 1px solid #000faa;
}

.webinarOverview__viewMode.active svg, .webinarOverview__viewMode:hover svg, .webinarOverview__viewMode:focus svg {
  background: #000faa;
  fill: #FFF;
}

.webinarItem {
  display: flex;
  margin-bottom: 24px;
}

.webinarItem__container {
  position: relative;
  width: 100%;
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.webinarItem__link {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 8px;
  right: 8px;
  overflow: hidden;
  text-indent: 200%;
  white-space: nowrap;
  z-index: 11;
}

.webinarItem__imageContainer {
  position: relative;
}

.webinarItem--obf .webinarItem__imageContainer {
  height: 0;
  background-color: #EFEFEF;
  padding-bottom: 56.25%;
}

.webinarItem__image {
  width: 100%;
  height: auto;
  display: block;
}

.webinarItem__image--container {
  height: 100%;
  object-fit: cover;
  padding-bottom: 24px;
}

.webinarItem--obf .webinarItem__image--container {
  height: 0;
  background-color: #EFEFEF;
  padding-bottom: 56.25%;
}

@media (min-width: 576px) {
  .webinarItem--obf .webinarItem__image--container {
    height: calc(100% - 24px);
    padding-bottom: 0;
  }
}

.webinarItem__gradient {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: none;
}

@media (min-width: 768px) {
  .webinarItem__gradient--large {
    background: none;
  }
}

.webinarItem__spotsLeft {
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.7);
  color: #202122;
  font-size: 1rem;
  font-style: italic;
  font-weight: 400;
  padding: 6px 0;
}

.webinarItem__spotsLeft.--full {
  color: #FF6700;
}

.webinarItem__spotsNr {
  color: #000faa;
}

.webinarItem__infoTitle {
  position: absolute;
  bottom: 100%;
  align-self: start;
  background: transparent;
  color: #202122;
  font-weight: 600;
  margin-bottom: 0;
  padding: .5rem 16px;
}

@media (min-width: 768px) {
  .webinarItem__infoTitle {
    position: relative;
    bottom: 0;
  }
}

.webinarItem__content {
  position: relative;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  margin-top: -24px;
}

.webinarItem__content--large {
  margin-top: -2.25rem;
}

@media (min-width: 768px) {
  .webinarItem__content--large {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }
}

.webinarItem__listContent {
  padding: 0 16px;
}

@media (min-width: 576px) {
  .webinarItem__listContent {
    padding-left: 0;
  }
}

.webinarItem__info {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.webinarItem__content--large .webinarItem__info {
  background: transparent;
  padding: .75rem 0 24px 0;
}

.webinarItem__infoLeft {
  display: flex;
}

.webinarItem__infoLeft--schedule {
  margin-top: -48px;
}

.webinarItem--obf .webinarItem__infoLeft--schedule {
  margin-top: -24px;
}

.webinarItem__titleContainer {
  flex: 1 1 0%;
  padding: 12px 16px 16px;
}

.webinarItem__content--large .webinarItem__titleContainer {
  flex-basis: 100%;
}

@media (min-width: 768px) {
  .webinarItem__content--large .webinarItem__titleContainer {
    flex-basis: 0%;
    padding-bottom: 0;
  }
}

.webinarItem__title {
  color: #202122;
  margin-bottom: 0;
}

.webinarItem__title--list {
  padding-top: 12px;
  margin-bottom: .5rem;
}

.webinarItem__readmore {
  display: flex;
  align-items: center;
  color: #FF6700;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.2;
  margin-top: .75rem;
  transition: color 0.15s ease-in-out;
}

@media (min-width: 768px) {
  .webinarItem__readmore {
    margin-bottom: .625rem;
  }
}

.webinarItem__link:hover ~ .webinarItem__container .webinarItem__readmore,
.webinarItem__link:focus ~ .webinarItem__container .webinarItem__readmore {
  color: #d95800;
}

.webinarItem__readmore svg {
  width: 16px;
  height: 16px;
  display: inline-block;
  fill: currentColor;
  transition: fill 0.15s ease-in-out;
}

.webinarItem__btnContainer {
  display: flex;
  flex-direction: column;
  padding: 0 16px 24px;
}

@media (min-width: 421px) {
  .webinarItem__btnContainer {
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.webinarItem__content--large .webinarItem__btnContainer {
  align-self: end;
  flex-basis: 100%;
  padding-bottom: 0;
}

@media (min-width: 768px) {
  .webinarItem__content--large .webinarItem__btnContainer {
    flex-basis: auto;
    padding-left: 0;
  }
}

.webinarItem__btnContainer .button {
  z-index: 12;
}

.webinarItem--obf .webinarItem__btnContainer .button {
  width: 120px;
  background: #EFEFEF;
}

.webinarItem__subtitle {
  color: #202122;
  font-weight: 400;
}

.webinarItem__code {
  font-size: 0.6875rem;
  padding-top: 10px;
  color: #a2a2a2;
}

.webinarItem__id {
  font-size: 0.6875rem;
  padding: 0 12px;
  color: #bababa;
}

.webinarItem__datetime {
  background-color: #FFF;
  display: flex;
  align-items: center;
  padding: 8px 6px;
}

.webinarItem--obf .webinarItem__datetime {
  width: 110px;
  min-height: 48px;
}

.webinarItem__date, .webinarItem__time {
  padding: 0 10px;
  line-height: 1rem;
  text-align: center;
}

.webinarItem__time {
  height: 100%;
  border-left: 1px solid #D8D8D8;
  display: flex;
  align-items: center;
}

.webinarItem__day {
  color: #202122;
  font-size: 1.3125rem;
  font-weight: 700;
}

.webinarItem__month {
  text-transform: uppercase;
  color: #0058ff;
  font-size: .6875rem;
  font-weight: 600;
}

.webinarItem__hour {
  font-size: 1.3125rem;
  font-weight: 600;
  color: #0058ff;
}

.webinarItem__demand {
  margin-left: 1px;
  background: #FFF;
}

.webinarItem__demandIcon {
  background-image: url("data:image/svg+xml,%3Csvg id='onDemand-icon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50' fill='%23202122'%3E%3Ctitle%3EonDemand-icon%3C/title%3E%3Cpolygon points='22.402 22 22.402 31 30.196 26.5 22.402 22'/%3E%3Cpath d='M27.805,13.3l3.5-2.388L30.18,9.257l-6.57,4.48,4.481,6.57,1.652-1.127-2.721-3.991a11.481,11.481,0,1,1-7.772,1.35l-1-1.732a13.506,13.506,0,1,0,9.557-1.51Z'/%3E%3C/svg%3E");
  width: 50px;
  height: 48px;
}

.webinarItem__bottom {
  margin-top: 1.5rem;
  margin-bottom: 24px;
}

.webinarItem__bottom > .h5 {
  color: #202122;
  margin-bottom: 0;
}

.webinarItem__facultyContainer {
  align-items: start;
}

@media (min-width: 768px) {
  .webinarItem__facultyContainer {
    margin-bottom: -.5rem;
  }
}

.webinarItem__member {
  margin: .5rem 0;
  padding: 0 8px;
}

.webinarItem--obf .webinarItem__member {
  width: 30%;
  min-width: 160px;
}

.webinarItem__listBtnContainer {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  padding: 0 8px;
}

@media (min-width: 576px) {
  .webinarItem__listBtnContainer {
    flex: 0 0 auto;
    margin-top: .5rem;
    margin-bottom: .5rem;
  }
}

.webinarItem__listBtnContainer > .button, .webinarItem__listBtnContainer > form {
  z-index: 12;
}

.webinarItem--obf .webinarItem__listBtnContainer > .button, .webinarItem--obf .webinarItem__listBtnContainer > form {
  width: 100%;
  min-width: 120px;
  background: #EFEFEF;
}

.webinarItem__listBtnContainer > form button {
  width: 100%;
}

.webinarItem__unsub {
  font-size: .875rem;
  margin-top: .75rem;
  margin-bottom: -.5rem;
  text-align: center;
}

@media (min-width: 421px) {
  :not(.webinarItem__listBtnContainer) > .webinarItem__unsub {
    margin-left: .5rem;
  }
}

.webinarDetail {
  background: #FFF;
  background-repeat: no-repeat;
  background-position: top center, bottom center;
  background-size: auto;
  padding-bottom: 64px;
}

.webinarDetail__inner {
  padding: 32px 0;
  background-color: transparent;
}

.webinarDetail__header {
  padding-bottom: 32px;
}

.webinarDetail__infoCol {
  order: 2;
}

.webinarDetail__contentCol {
  order: 1;
}

.webinarDetail__title {
  color: #0058ff;
  font-weight: 700;
}

.webinarDetail__subTitle {
  color: #202122;
}

.webinarDetail__subTitle:not(:empty) {
  padding-bottom: 1rem;
}

.webinarDetail__date {
  font-size: 1.25rem;
  font-weight: normal;
}

.webinarDetail__contentTitle {
  font-size: 1.5rem;
  font-weight: 500;
  color: #0058ff;
  padding: 20px 0;
}

.webinarDetail__visual {
  margin-bottom: 24px;
}

.webinarDetail__visual > img,
.webinarDetail__visual > video {
  width: 100%;
  height: auto;
}

.webinarDetail__visual--obf {
  height: 0;
  background-color: #EFEFEF;
  padding-bottom: 56.25%;
}

.webinarDetail__description {
  margin-bottom: 24px;
}

.webinarDetail__description--blur {
  position: relative;
  filter: blur(4px);
  margin-bottom: 0;
}

.webinarDetail__description--blur::after {
  position: absolute;
  top: -8px;
  right: -8px;
  bottom: -8px;
  left: -8px;
  background: linear-gradient(rgba(255, 255, 255, 0), #FFF);
  content: '';
}

.webinarDetail #player {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
  height: 100%;
}

.webinarDetail__mediaInner {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
  padding: 24px 0 32px;
}

@media (min-width: 768px) {
  .webinarDetail__mediaInner {
    flex-wrap: nowrap;
  }
}

.webinarDetail__tags {
  flex: 1 1 100%;
  margin-bottom: 16px;
  padding: 0 8px;
}

.webinarDetail__tagsTitle {
  margin-bottom: 1rem;
}

.webinarDetail__tagsContainer {
  display: flex;
  flex-wrap: wrap;
}

.webinarDetail__infoBlock {
  position: sticky;
  top: 16px;
  background-color: #000faa;
  color: #FFF;
  overflow: hidden;
  padding: 16px;
}

.webinarDetail__infoTitle {
  color: #FFF;
  display: block;
  font-weight: 400;
  margin-bottom: .375rem;
}

.webinarDetail__infoBlock--obf .webinarDetail__infoTitle {
  width: 120px;
}

.webinarDetail__date {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 20px;
  padding-bottom: 12px;
}

.webinarDetail__date > span {
  font-size: 1rem;
  font-weight: normal;
}

.webinarDetail__infoBlock--obf .webinarDetail__date {
  width: 180px;
}

.webinarDetail__times {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px 16px -16px;
}

.webinarDetail__durationWrapper, .webinarDetail__timeWrapper {
  flex: 1 1 auto;
  margin-bottom: 16px;
  padding: 0 16px;
}

.webinarDetail__durationWrapper {
  position: relative;
  padding-left: 17px;
}

.webinarDetail__durationWrapper::after {
  position: absolute;
  left: -1px;
  top: 0;
  height: 100%;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.5);
  content: '';
}

.webinarDetail__time {
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 1;
}

.webinarDetail__time span {
  font-size: 1rem;
  font-weight: normal;
}

.webinarDetail__timeTitle {
  color: #FFF;
}

.webinarDetail__durationTitle {
  color: #FFF;
}

.webinarDetail__duration {
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 1;
}

.webinarDetail__infoBlock--obf .webinarDetail__duration {
  width: 180px;
}

.webinarDetail__spotsLeft {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  font-style: italic;
  line-height: 1;
  margin: -12px 0 20px 0;
  padding-top: 12px;
  text-align: center;
}

.webinarDetail__btnContainer .button, .webinarDetail__btnContainer form {
  width: 100%;
}

.webinarDetail__btnContainer > .button, .webinarDetail__btnContainer > form {
  margin-bottom: 16px;
}

.webinarDetail__faculty {
  margin-bottom: 24px;
}

.webinarDetail__facultyTitle {
  color: #0058ff;
  padding: 20px 0;
  font-size: 1.5rem;
  font-weight: 500;
}

@media screen and (max-width: 576px) {
  .webinarDetail__durationTitle {
    padding-top: 15px;
  }
}

.content {
  position: relative;
  background-color: transparent;
  padding: 32px 0;
}

.content__text {
  margin-bottom: 24px;
}

.content__text--blur {
  position: relative;
  filter: blur(4px);
  margin-bottom: 0;
}

.content__text--blur::after {
  position: absolute;
  top: -8px;
  right: -8px;
  bottom: -8px;
  left: -8px;
  background: linear-gradient(rgba(255, 255, 255, 0), #FFF);
  content: '';
}

.contentOverview {
  background: #FFF;
  background-repeat: no-repeat;
  background-position: top center, bottom center;
  background-size: auto;
  padding-bottom: 64px;
}

.contentOverview__inner {
  padding: 32px 0;
  background-color: transparent;
}

.facultyOverview {
  background: #FFF;
  background-repeat: no-repeat;
  background-position: top center, bottom center;
  background-size: auto;
  padding-bottom: 64px;
}

.facultyOverview__inner {
  padding: 32px 0;
  background-color: transparent;
}

.facultyOverview__item {
  display: flex;
  flex-direction: column;
}

.facultyOverview__title {
  color: #202122;
  margin-bottom: 1.5rem;
}

.facultyOverview__description {
  margin-bottom: 20px;
  margin-bottom: 3rem;
}

.facultyDetail {
  background: #FFF;
  background-repeat: no-repeat;
  background-position: top center, bottom center;
  background-size: auto;
  padding-bottom: 64px;
  /*&__employer {
		font-size: 0.875rem;
		font-weight: normal;
	}*/
  /*&__date {
		font-size: 1.25rem;
		font-weight: normal;
	}*/
  /*&__contentInfo {
		font-size: 1.25rem;
		font-weight: 500;
		padding: 20px 0;
	}*/
  /*&__contentMostread {
		font-size: 1rem;
		font-weight: 500;
		padding: 30px 0 10px;
	}*/
  /*&__contentDate {
		font-size: 0.6875rem;
		font-weight: 500;
		color: #5c5c5c;
	}*/
  /*#player {
		position: absolute;
		left: 0;
		width: 100%;
		bottom: 0;
		height: 100%;
		z-index: 100;
	}*/
  /*&__tagsTitle {
		padding-bottom: 15px;
	}*/
}

.facultyDetail__inner {
  padding: 32px 0;
  background-color: transparent;
}

.facultyDetail__btnContainer {
  text-align: center;
  margin-top: 32px;
}

.facultyDetail__profile {
  display: flex;
  align-items: center;
  margin-bottom: 36px;
}

.facultyDetail__header {
  margin-bottom: 48px;
}

.facultyDetail__name {
  font-weight: bold;
  color: #000faa;
}

.facultyDetail__image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 16px;
}

.facultyDetail__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.facultyDetail__country {
  font-weight: 600;
  color: #202122;
}

.facultyDetail__contentTitle {
  color: #0058ff;
  margin-bottom: 1rem;
}

.facultyDetail__description {
  margin-bottom: 48px;
}

.facultyDetail__video {
  width: 100%;
  margin-top: 32px;
}

@media (min-width: 992px) {
  .facultyDetail__video {
    margin-top: 0;
  }
}

.facultyDetail__text {
  margin-bottom: 24px;
}

.facultyDetail__text--blur {
  position: relative;
  filter: blur(4px);
  margin-bottom: 0;
}

.facultyDetail__text--blur::after {
  position: absolute;
  top: -8px;
  right: -8px;
  bottom: -8px;
  left: -8px;
  background: linear-gradient(rgba(255, 255, 255, 0), #FFF);
  content: '';
}

/*
@media screen and (max-width: 992px) {
	.facultyDetail {
		&__tagsTitle {
			padding: 15px;
		}

		&__shareTitle {
			padding: 15px;
		}
	}
}*/
.homeNewsGrid {
  background-color: #f7f7f7;
}

.modal-wrapper {
  padding-right: 0 !important;
}

.modal-wrapper__close {
  position: absolute;
  top: 0;
  right: 0;
  width: 48px;
  height: 48px;
  background: transparent;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  padding: 16px;
}

.modal-wrapper__close:focus {
  outline: 0;
}

.modal-wrapper__close svg {
  width: 16px;
  height: 16px;
  display: block;
  fill: #1D1D1B;
}

.modal-wrapper__dialog {
  position: relative;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding: 16px 8px;
  text-align: center;
  transition: transform 0.3s ease-out;
}

.modal-wrapper__dialog--lg {
  max-width: 1200px;
}

.modal-wrapper__content {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  background-clip: padding-box;
  border-radius: 0;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.16);
  outline: 0;
  padding: 32px 16px;
  pointer-events: auto;
  min-height: 320px;
  justify-content: center;
}

.modal-wrapper__innerContent {
  width: 100%;
  max-width: 512px;
  margin: 0 auto;
}

.modal-wrapper__title {
  color: #000faa;
  font-weight: 700;
  margin-bottom: 1.5rem;
}

.modal-wrapper__introtitle {
  color: #9B9B9B;
  font-weight: 400;
  margin-bottom: 24px;
}

.modal-wrapper__text {
  font-family: "myriad-pro", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  margin-bottom: 1.5rem;
}

.modal-wrapper__text--bold {
  font-weight: 600;
}

.modal-wrapper__text--linkContainer {
  margin: 16px 0 0;
}

.modal-wrapper__socialAccountLogin {
  padding-top: 40px;
}

.modal-wrapper__socialAccountTitle {
  margin-bottom: 1.5rem;
  color: #202122;
}

.modal-backdrop {
  background-color: rgba(255, 255, 255, 0.92);
}

.modal-backdrop.show {
  opacity: 1;
}

#chartdiv {
  width: 100%;
  height: 50vw;
  min-height: 320px;
  max-height: 500px;
  margin-bottom: 32px;
}

.countryName {
  margin-bottom: 1.5rem;
}

.form__group {
  text-align: left;
}

.form input,
.form textarea, .form__select {
  width: 100%;
  border-color: #9B9B9B;
  border-width: 0 0 2px;
  border-radius: 0;
  color: #202122;
  font-size: 1rem;
  font-weight: 500;
  outline: 0;
  background-color: transparent;
}

.form input:focus,
.form textarea:focus, .form__select:focus {
  border-color: #000faa;
}

.form input::placeholder,
.form textarea::placeholder, .form__select::placeholder {
  color: #5C5C5C;
  opacity: 1;
}

.form input, .form textarea {
  padding: 12px 40px 12px 0;
}

.form__select {
  position: relative;
  appearance: none;
  cursor: pointer;
  padding: 12px 40px 12px 0;
}

.form__iconSelect {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: rotate(90deg);
  margin-top: -10px;
  pointer-events: none;
  transition: transform .3s ease-in-out;
}

.form__iconSelect--up {
  transform: rotate(270deg);
}

.form__iconSelect svg {
  display: block;
  width: 20px;
  height: 20px;
  fill: #202122;
}

.form__radio {
  padding-bottom: 14px;
}

.form__radio [type="radio"]:checked,
.form__radio [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.form__radio [type="radio"]:checked + label,
.form__radio [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #202122;
}

.form__radio [type="radio"]:checked + label::before,
.form__radio [type="radio"]:not(:checked) + label::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  border: 1px solid #000faa;
  border-radius: 100%;
  background: #fff;
}

.form__radio [type="radio"]:checked + label::after,
.form__radio [type="radio"]:not(:checked) + label::after {
  content: '';
  width: 12px;
  height: 12px;
  background: #000faa;
  position: absolute;
  top: 6px;
  left: 6px;
  border-radius: 100%;
  transition: all 0.2s ease;
}

.form__radio [type="radio"]:not(:checked) + label::after {
  opacity: 0;
  transform: scale(0);
}

.form__radio [type="radio"]:checked + label::after {
  opacity: 1;
  transform: scale(1);
}

.form__radio label {
  font-size: 1rem;
}

.form__error, .form__errorMessage,
.form .customCheckbox label.error {
  width: 100%;
  color: #e03100;
  float: left;
  font-size: 0.875rem;
  font-family: "myriad-pro", sans-serif;
  font-weight: 300;
  text-align: left;
}

.form__error:not(:empty), .form__errorMessage:not(:empty),
.form .customCheckbox label.error:not(:empty) {
  margin: 8px 0;
}

.form__error--top, .form__errorMessage--top,
.form .customCheckbox label.error--top {
  display: block;
  float: none;
}

.form__passwordBtn {
  width: 20px;
  height: 20px;
  line-height: 1;
}

.form--passwordBtnVisible {
  display: none;
}

.form__toggleBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  right: 0;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transform: translate(0, -50%);
  background-color: transparent;
  border: 0;
}

.form__toggleBtn svg {
  fill: #000faa;
}

.form__toggleBtn:focus {
  outline: 0;
}

.form__media {
  font-size: 1.25rem;
  font-weight: 500;
  padding: 45px 0 18px;
}

.form__inputContainer {
  position: relative;
  margin-bottom: 48px;
}

.form__inputContainer--notificationPreference {
  margin-bottom: 16px;
}

.form__inputContainer--withTooltip > input {
  padding-right: 42px;
}

.form__inputContainer--withTooltip > .customDate input {
  padding-right: 50px;
}

.form__inputContainer--withTooltip.dateInput .form__tooltip {
  top: 28%;
}

.form__inputContainer--withTooltip > .customCheckbox {
  padding-right: 42px;
}

.form__inputContainer--withTooltip.checkboxInput .form__tooltip {
  top: 14px;
}

.form__inputContainer--withTooltip > select {
  padding-right: 80px;
}

.form__inputContainer--withTooltip > .form__iconSelect {
  right: 52px;
}

.form__tooltip {
  position: absolute;
  top: 50%;
  right: -1px;
  width: 42px;
  height: 42px;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  padding: 9px;
  transform: translate(0, -50%);
  z-index: 1;
}

.form__icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: #000faa;
  border-radius: 50%;
  color: #FFF;
  text-align: center;
}

.form .input-validation-error {
  border-color: #e03100;
}

.form__text {
  font-family: "myriad-pro", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  margin-bottom: 1.5rem;
}

.form__text--left {
  text-align: left;
}

.form__deleteBtnContainer {
  margin-top: 48px;
}

.formProgress {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 48px 0 32px 0;
  padding: 0;
  list-style: none;
}

.formProgress__item {
  position: relative;
  width: 40px;
  height: 40px;
  background: 0 0;
  border: 0;
  border-radius: 50%;
  margin: 0;
  padding: 0;
}

.formProgress__item::before {
  top: 14px;
  left: 14px;
  width: 12px;
  height: 12px;
  opacity: 0.6;
  background-color: #000faa;
}

.formProgress__item::after {
  top: 8px;
  left: 8px;
  width: 24px;
  height: 24px;
}

.formProgress__item::before, .formProgress__item::after {
  position: absolute;
  border-radius: 50%;
  content: '';
  -webkit-transition: .15s ease-in;
  transition: .15s ease-in;
}

.formProgress .finished::before {
  opacity: 1;
}

.formProgress .current::before {
  opacity: 1;
}

.formProgress .current::after {
  border: 1px solid #000faa;
}

select::-ms-expand {
  display: none;
}

[tooltip]::before, [tooltip]::after {
  text-transform: none;
  font-size: 0.875rem;
  line-height: 1.5;
  user-select: none;
  pointer-events: none;
  position: absolute;
  display: none;
  opacity: 0;
}

[tooltip]::before {
  content: '';
  border: 5px solid transparent;
  z-index: 1001;
}

[tooltip]::after {
  content: attr(tooltip);
  text-align: center;
  width: 300px;
  max-width: 300px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 10px 20px -0.5px rgba(0, 0, 0, 0.35);
  background: #000faa;
  color: #FFF;
  z-index: 1000;
}

[tooltip]:focus::before, [tooltip]:focus::after, [tooltip]:hover::before, [tooltip]:hover::after {
  display: block;
}

/* don't show empty tooltips */
[tooltip='']::before, [tooltip='']::after {
  display: none;
}

/* FLOW: DOWN */
[tooltip][flow^="down"]::before {
  top: 100%;
  border-top-width: 0;
  border-bottom-color: #000faa;
  transform: translate(-10px, -5px);
  right: 5px;
}

[tooltip][flow^="down"]::after {
  top: 100%;
  left: auto;
  right: 0;
}

/* KEYFRAMES */
@keyframes tooltips-vert {
  to {
    opacity: .9;
  }
}

[tooltip]:not([flow]):focus::before,
[tooltip]:not([flow]):focus::after,
[tooltip][flow^="down"]:focus::before,
[tooltip][flow^="down"]:focus::after,
[tooltip]:not([flow]):hover::before,
[tooltip]:not([flow]):hover::after,
[tooltip][flow^="down"]:hover::before,
[tooltip][flow^="down"]:hover::after {
  animation: tooltips-vert 300ms ease-out forwards;
}

.customCheckbox {
  position: relative;
  display: block;
  text-align: left;
}

.customCheckbox__label {
  padding-left: 2rem;
  font-size: 0.875rem;
  cursor: pointer;
  position: relative;
}

.customCheckbox__label::before {
  position: absolute;
  top: -2px;
  left: 0;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  background-color: #FFF;
  border: 2px solid #9B9B9B;
  border-radius: 4px;
  content: '';
  pointer-events: none;
  transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
}

.customCheckbox__label::after {
  position: absolute;
  top: -1px;
  left: 1px;
  width: 1.5rem;
  height: 1rem;
  content: '';
  display: block;
}

.customCheckbox input[type=checkbox] {
  position: absolute;
  left: 0;
  width: auto;
  opacity: 0;
  z-index: -1;
}

.customCheckbox input:checked ~ .customCheckbox__label::before {
  border-color: #000faa;
}

.customCheckbox input:checked ~ .customCheckbox__label::after {
  width: 1rem;
  height: 0.5rem;
  border: 2px solid #000faa;
  border-top-style: none;
  border-right-style: none;
  transform: translate(0.25em, 0.33654em) rotate(-45deg);
}

.customCheckbox input:focus:not(:checked) ~ .customCheckbox__label::before {
  border-color: #000faa;
}

.customCheckbox input:focus ~ .customCheckbox__label::before {
  box-shadow: 0 0 0 3px rgba(0, 15, 170, 0.25);
}

.customRadio {
  position: relative;
  display: block;
  text-align: left;
}

.customRadio__label {
  padding-left: 2rem;
  font-size: 0.875rem;
  cursor: pointer;
  position: relative;
}

.customRadio__label::before {
  position: absolute;
  top: -2px;
  left: 0;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  background-color: #FFF;
  border: 2px solid #9B9B9B;
  border-radius: 50%;
  content: '';
  pointer-events: none;
  transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
}

.customRadio__label::after {
  position: absolute;
  top: calc(0.375rem - 2px);
  left: 0.375rem;
  width: 0;
  height: 0;
  background-color: #000faa;
  border-radius: 50%;
  content: '';
  display: block;
}

.customRadio input[type=radio] {
  position: absolute;
  left: 0;
  width: auto;
  opacity: 0;
  z-index: -1;
}

.customRadio input:checked ~ .customRadio__label::before {
  border-color: #000faa;
}

.customRadio input:checked ~ .customRadio__label::after {
  width: 0.75rem;
  height: 0.75rem;
}

.customRadio input:focus:not(:checked) ~ .customCheckbox__label::before {
  border-color: #000faa;
}

.customRadio input:focus ~ .customCheckbox__label::before {
  box-shadow: 0 0 0 3px rgba(0, 15, 170, 0.25);
}

.errorPage {
  background: none;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: auto;
  padding-bottom: 180px;
}

.errorPage__content {
  padding: 100px 0;
}

.errorPage__title, .errorPage__subTitle {
  font-weight: 600;
  margin-bottom: 20px;
}

.errorPage__description {
  margin-bottom: 5px;
}

.videoModal__content {
  position: relative;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.videoModal__body {
  position: relative;
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  padding: 1rem;
}

.videoModal__dialog {
  max-width: 800px;
}

.videoModal__close {
  border: 0;
  background: transparent;
  float: right;
}

.videoModal__close svg {
  width: 20px;
  height: 20px;
  fill: #1D1D1B;
  margin-top: 5px;
}

.profile__title {
  margin: 2rem 0;
}

.profile__account {
  padding: 7px 0 40px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.account {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  max-width: 320px;
  padding: 12px 8px;
  cursor: pointer;
  outline: none;
  border: 0;
  background: transparent;
  transition: all 0.3s ease-out;
}

.account:focus, .account:hover {
  background: #fff;
}

@media (min-width: 576px) {
  .account {
    min-width: 160px;
  }
}

.account--active {
  background: #fff;
}

.account__member {
  text-align: right;
  padding-right: 10px;
  min-width: 0;
  display: none;
}

@media (min-width: 576px) {
  .account__member {
    display: block;
  }
}

.account__title {
  font-size: 0.75rem;
  font-weight: 600;
  color: #5c5c5c;
}

.account__name {
  font-size: 0.75rem;
  font-weight: 600;
  color: #000faa;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.account__avatar {
  width: 32px;
  height: 32px;
  border: 1px solid #000faa;
  border-radius: 50%;
}

.account svg {
  width: 30px;
  fill: #000faa;
}

.account__menu {
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  min-width: 240px;
  display: none;
  background-color: #fff;
  z-index: 1000;
}

@media (min-width: 576px) {
  .account__menu {
    min-width: 0;
  }
}

.account__menuList {
  list-style-type: none;
  margin: 8px 0;
  padding: 0;
}

.account__menuItem {
  position: relative;
}

.account__menuLink {
  display: block;
  color: #000faa;
  font-size: 0.75rem;
  font-weight: 500;
  padding: 16px;
  text-decoration: none;
  text-align: left;
  transition: all 300ms ease-in-out;
}

.account__menuLink:hover, .account__menuLink:focus {
  background: #F1F1F1;
  color: #000faa;
  text-decoration: none;
}

.account__menuLink--logout {
  color: #e03100;
}

.account__menuLink--logout:hover, .account__menuLink--logout:focus {
  color: #e03100;
}

.account__menuLink--logout::before {
  position: absolute;
  top: 0;
  left: 16px;
  right: 16px;
  height: 1px;
  background: #EBEBEB;
  content: '';
}

.dropdown__select {
  width: 100%;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 140 140' width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Cpath d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='%23000faa'/%3E%3C/g%3E%3C/svg%3E");
  background-position: calc(100% - 8px) center;
  background-repeat: no-repeat;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  color: #000faa;
  cursor: pointer;
  font-size: 1rem;
  padding: 8px 12px;
}

@media screen and (min-width: 576px) {
  .dropdown__select {
    max-width: 400px;
  }
}

.dropdown__select option {
  padding: 10px;
}

/*** the following rule remove default arrow on HTML tag select - IE10+ ***/
.dropdown__select::-ms-expand {
  display: none;
}

.forumMenu {
  cursor: pointer;
  padding: 15px 20px;
  width: 230px;
}

.forumMenu.active {
  background-color: #fff;
  padding-top: 15px;
}

.forumMenu.active .loggedMenu__title svg {
  width: 8px;
  top: 4px;
  transform: rotate(270deg);
  fill: #000faa;
}

.forumMenu__open {
  list-style: none;
  width: 230px;
  padding: 5px 20px 20px;
  height: auto;
  display: none;
  position: absolute;
  z-index: 10;
  background-color: #fff;
}

.forumMenu__open--title {
  font-size: 1rem;
  color: #000faa;
  margin-bottom: 15px;
  padding-bottom: 17px;
  border-bottom: 1px solid #ebebeb;
}

.forumMenu__link {
  font-size: 1rem;
  color: #000faa;
  line-height: 32px;
  margin-bottom: 2px;
  font-weight: 500;
  display: block;
}

.forumMenu__link::before {
  content: ' ';
  border: 2px solid #707070;
  margin-right: 15px;
}

.forumMenu__title {
  position: relative;
  font-size: 1rem;
}

.forumMenu__title svg {
  width: 8px;
  top: 4px;
  transform: rotate(90deg);
  position: absolute;
  fill: #000faa;
  right: 7px;
}

.highlight {
  position: relative;
  background: #000faa;
  padding: 32px 0;
}

.highlight__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.highlight__container {
  position: relative;
}

.highlight__title {
  color: #FFF;
  margin-bottom: 1.5rem;
}

.highlight__info {
  text-align: center;
}

@media screen and (min-width: 992px) {
  .highlight__info {
    text-align: left;
  }
}

.highlight__name {
  color: #FFF;
  font-size: 1.75rem;
  font-weight: 700;
}

.highlight__date, .highlight__location {
  color: #FFF;
  font-family: "myriad-pro", sans-serif;
  line-height: 1.5;
  margin-bottom: 0;
}

.highlight__content {
  text-align: center;
}

.highlight__description {
  color: #FFF;
  font-family: "myriad-pro", sans-serif;
  font-size: 1.125rem;
  padding: 32px 0 24px 0;
  text-align: center;
}

@media screen and (min-width: 992px) {
  .highlight__description {
    font-size: 1.25rem;
    padding-top: calc(1.2 * 1.75rem + 0.5rem);
  }
}

.highlight__btnContainer {
  margin-top: 32px;
  text-align: center;
}

.cookieMessage {
  font-size: 1rem;
  color: #fff;
  background-color: #000060;
  padding: 16px;
  position: fixed;
  width: 100%;
  bottom: 0;
  overflow: hidden;
  z-index: 9999;
}

.cookieMessage__content {
  align-items: center;
}

.cookieMessage__privacyStatement {
  color: #fff;
  text-decoration: underline;
}

.cookieMessage__privacyStatement:hover {
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}

.cookieMessage__btnContainer {
  text-align: right;
}

@media screen and (max-width: 991px) {
  .cookieMessage__btnContainer {
    padding: 16px 0 0;
    width: auto;
    margin: auto;
  }
}

.myProfile {
  padding-top: 20px;
  padding-bottom: 20px;
}

.myProfile__title {
  color: #202122;
  margin-bottom: 2rem;
}

.myProfile .nav-tabs {
  margin-bottom: 40px;
}

.myProfile .nav-tabs .nav-item:not(:last-child) {
  margin-right: 16px;
}

@media (min-width: 576px) {
  .myProfile .nav-tabs .nav-item:not(:last-child) {
    margin-right: 24px;
  }
}

@media (min-width: 768px) {
  .myProfile .nav-tabs .nav-item:not(:last-child) {
    margin-right: 32px;
  }
}

.myProfile .nav-tabs .nav-link {
  position: relative;
  background-color: transparent;
  border: none;
  color: #202122;
  font-size: .875em;
  padding: 8px 0;
  text-decoration: none;
  transition: all 0.15s ease-in-out;
}

@media (min-width: 576px) {
  .myProfile .nav-tabs .nav-link {
    font-size: 1em;
  }
}

.myProfile .nav-tabs .nav-link::after {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 0;
  width: 100%;
  background: #074f6f;
  content: '';
  opacity: 0;
  transition: all 0.15s ease-in-out;
}

.myProfile .nav-tabs .nav-link.active {
  color: #074f6f;
  font-weight: 600;
}

.myProfile .nav-tabs .nav-link:hover::after, .myProfile .nav-tabs .nav-link.active::after, .myProfile .nav-tabs .nav-link:focus::after {
  height: 3px;
  opacity: 1;
}

.myProfile__notifications {
  margin-bottom: 32px;
}

.myProfile__delete {
  margin-top: 64px;
}

.myProfile__delete > .button {
  width: 100%;
}

@media screen and (min-width: 421px) {
  .myProfile__delete > .button {
    width: auto;
  }
}

.contentPage {
  padding-top: 32px;
}

.contentPage p + h2,
.contentPage p + h3,
.contentPage p + h4,
.contentPage p + h5,
.contentPage p + h6 {
  margin-top: 1.5em;
}

.contentPage .responsiveTable {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.contentPage table {
  width: 100%;
  vertical-align: top;
}

.contentPage table thead {
  vertical-align: bottom;
}

.contentPage table tbody {
  vertical-align: inherit;
}

.contentPage table td, .contentPage table th {
  border: 1px solid #ccc;
  padding: .5rem .5rem;
}

.moreWebinar {
  margin-bottom: 24px;
  display: none;
}

@media screen and (min-width: 992px) {
  .moreWebinar {
    display: block;
  }
}

.moreWebinar__container {
  position: relative;
  height: 100%;
  min-height: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(/assets/dist/images/check-back_bg.svg) right bottom no-repeat;
  background-size: contain;
  background-color: #e6edf0;
}

.moreWebinar__content {
  color: #0058ff;
  font-family: "myriad-pro", "Arial", sans-serif;
  padding: 16px;
  text-align: center;
}

.previousWebinars {
  background-color: transparent;
  padding: 32px 0;
}

.previousWebinars--home {
  background-color: transparent;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .previousWebinars--home .webinarItem:nth-child(1):nth-last-child(1) {
    flex-basis: 100%;
    max-width: 100%;
  }
}

@media screen and (min-width: 992px) {
  .previousWebinars--home .webinarItem:nth-child(1):nth-last-child(2), .previousWebinars--home .webinarItem:nth-child(2):nth-last-child(1) {
    flex-basis: 50%;
    max-width: 50%;
  }
}

.previousWebinars--home .webinarItem:nth-child(3) {
  display: none;
}

@media screen and (min-width: 992px) {
  .previousWebinars--home .webinarItem:nth-child(3) {
    display: flex;
  }
}

.previousWebinars__btnContainer {
  margin-top: 32px;
  text-align: center;
}

.previousWebinars__title {
  color: #0058ff;
  margin-bottom: 1.5rem;
}

.media {
  display: block;
  border-top: 1px solid #d8d8d8;
}

.media__inner {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
  padding: 24px 0 32px;
}

@media (min-width: 768px) {
  .media__inner {
    flex-wrap: nowrap;
  }
}

.media__container {
  flex: 1 1 100%;
  margin-bottom: 16px;
  padding: 0 8px;
}

.media__title {
  margin-bottom: 1rem;
}

.media__attachmentContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.media__attachment {
  position: relative;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  color: #000faa;
  padding: 16px 16px 16px 48px;
  text-decoration: none;
  transition: all 0.15s ease-in-out;
  user-select: none;
  word-break: break-all;
}

.media__attachment:focus, .media__attachment:hover {
  color: #000d91;
  text-decoration: none;
}

.media__attachment:not(:last-child) {
  margin-bottom: 1rem;
}

.media__attachment svg {
  position: absolute;
  left: 12px;
  top: 9px;
  width: 22px;
  height: auto;
  stroke: #202122;
}

.media__tagContainer {
  display: flex;
  flex-wrap: wrap;
}

.media__shareContainer {
  display: flex;
  flex-wrap: wrap;
}

.media__sharebtn {
  display: inline-block;
  background-color: #000faa;
  border-radius: 50%;
  cursor: pointer;
  padding: 12px;
  margin-bottom: 16px;
  transition: all 0.15s ease-in-out;
}

.media__sharebtn:not(:last-of-type) {
  margin-right: 12px;
}

.media__sharebtn svg {
  display: block;
  width: 24px;
  height: 24px;
}

.media__sharebtn svg path {
  fill: #fff;
}

.media__sharebtn--facebook:hover, .media__sharebtn--facebook:focus {
  background-color: #4267B2;
}

.media__sharebtn--linkedin:hover, .media__sharebtn--linkedin:focus {
  background-color: #0072b1;
}

.media__sharebtn--twitter:hover, .media__sharebtn--twitter:focus {
  background-color: #00acee;
}

.media__sharebtn--email:hover, .media__sharebtn--email:focus {
  background-color: #000d91;
}

.media__sharebtn--whatsapp:hover, .media__sharebtn--whatsapp:focus {
  background-color: #25D366;
}

.media__sharebtn--showOnMobile {
  display: inline-block;
}

@media screen and (min-width: 576px) {
  .media__sharebtn--showOnMobile {
    display: none;
  }
}

.filter {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 16px;
}

@media screen and (min-width: 576px) {
  .filter {
    padding-bottom: 24px;
  }
}

@media screen and (min-width: 768px) {
  .filter {
    padding-bottom: 32px;
  }
}

@media screen and (min-width: 992px) {
  .filter {
    padding-bottom: 48px;
  }
}

.filter--noPadding {
  padding-bottom: 0;
}

@media screen and (min-width: 576px) {
  .filter {
    flex-direction: row;
  }
}

.filter__listContainer {
  display: flex;
  order: 2;
  flex: 1 1 auto;
  line-height: 1.1875rem;
  padding-top: 24px;
}

@media screen and (min-width: 576px) {
  .filter__listContainer {
    order: 1;
    padding-top: 0;
  }
}

.filter__list {
  position: relative;
  top: 3px;
  display: inline-block;
  flex: 1 1 auto;
  padding: 0;
  margin: 0;
}

.filter__listItem {
  font-size: 1rem;
  display: inline-block;
  font-weight: 500;
  margin: 8px 0;
}

.filter__listItem:not(:last-of-type) {
  margin-right: 32px;
}

.filter__listItem--all {
  font-weight: 700;
}

.filter__link {
  position: relative;
  color: #202122;
  padding-bottom: 5px;
  text-decoration: none;
  transition: all 0.15s ease-in-out;
}

.filter__link.active {
  color: #000faa;
  position: relative;
}

.filter__link.active::after {
  height: 2px;
  opacity: 1;
}

.filter__link::after {
  content: '';
  position: absolute;
  bottom: 0;
  height: 0;
  opacity: 0;
  width: 100%;
  background-color: #000faa;
  left: 0;
  transition: all 0.15s ease-in-out;
}

.filter__link:not(.active):hover, .filter__link:not(.active):focus {
  text-decoration: none;
  color: #202122;
}

.filter__link:not(.active):hover::after, .filter__link:not(.active):focus::after {
  height: 2px;
  opacity: 1;
}

.filter__searchContainer {
  order: 1;
  margin-left: 0;
}

.dropdown__select ~ .filter__searchContainer {
  margin-top: 1.5rem;
}

@media screen and (min-width: 576px) {
  .filter__searchContainer {
    order: 2;
    margin-left: auto;
  }
  .dropdown__select ~ .filter__searchContainer {
    margin-top: 0;
    padding-left: 1rem;
  }
}

.filter__search {
  position: relative;
}

.filter__input {
  background: #FFF;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  color: #000faa;
  padding: 8px 48px 8px 16px;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
}

@media screen and (min-width: 576px) {
  .filter__input {
    width: 260px;
  }
}

.filter__button {
  background: none;
  border: none;
  cursor: pointer;
  overflow: hidden;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  text-indent: 48px;
  width: 48px;
  height: 100%;
  transition: all 0.15s ease-in-out;
}

.filter__button:hover, .filter__button:focus {
  outline: 0;
}

.filter__button:hover .filter__icon, .filter__button:focus .filter__icon {
  fill: #000faa;
}

.filter__icon {
  fill: #000faa;
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translate(0, -50%);
  width: 22px;
  height: 22px;
}

.relatedItems {
  background-color: transparent;
  padding: 32px 0;
  position: relative;
}

.relatedItems::before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
  width: calc(100% - 32px);
  max-width: calc(1140px - 32px);
  background: #d8d8d8;
  height: 1px;
  top: 0;
  margin: auto;
}

.relatedItems__title {
  color: #202122;
  margin-bottom: 1.5rem;
}

.stepContainer {
  display: none;
  transition: all .15s ease-in-out;
}

.stepContainer.show {
  display: block;
}

.loader {
  position: relative;
  margin: 0 auto;
  text-align: center;
}

.loader__circular {
  stroke: #000faa;
  width: 48px;
  height: 48px;
  animation: rotate 2s linear infinite;
  transform-origin: center;
}

.loader__path {
  fill: none;
  stroke-dasharray: 1, 140;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-width: 3px;
  animation: dash 2s cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 140;
    stroke-dashoffset: 0;
  }
  45%, 55% {
    stroke-dasharray: 140, 140;
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dasharray: 140, 140;
    stroke-dashoffset: -140;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.mainHeader {
  background-color: #FFF;
  border-bottom: 1px solid #e4e4e6;
}

.mainHeader__brand {
  height: 40px;
  background-color: #FFF;
  text-align: right;
}

.mainHeader__brandLogo {
  width: auto;
  height: 18px;
  display: inline-block;
  margin: 11px 0;
}

.news {
  position: relative;
  background-color: transparent;
  padding: 32px 0;
}

.news--home {
  background-color: tansparent;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .news--home .newsItem:nth-child(1):nth-last-child(1) {
    flex-basis: 100%;
    max-width: 100%;
  }
}

@media screen and (min-width: 992px) {
  .news--home .newsItem:nth-child(1):nth-last-child(2), .news--home .newsItem:nth-child(2):nth-last-child(1) {
    flex-basis: 50%;
    max-width: 50%;
  }
}

.news--home .newsItem:nth-child(3) {
  display: none;
}

@media screen and (min-width: 992px) {
  .news--home .newsItem:nth-child(3) {
    display: flex;
  }
}

.news__title {
  color: #0058ff;
  margin-bottom: 1.5rem;
}

.news__btnContainer {
  margin-top: 32px;
  text-align: center;
}

.newsOverview {
  background: #FFF;
  background-repeat: no-repeat;
  background-position: top center, bottom center;
  background-size: auto;
  padding-bottom: 64px;
}

.newsOverview__inner {
  padding: 32px 0;
  background-color: transparent;
}

.newsItem {
  display: flex;
  margin-bottom: 24px;
}

.newsItem__container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: #FFF;
}

.newsItem__container:focus .button--readMore, .newsItem__container:hover .button--readMore {
  color: #0058ff;
}

.newsItem__container:focus .button--readMore svg, .newsItem__container:hover .button--readMore svg {
  fill: #0058ff;
}

.newsItem__image {
  width: 100%;
  height: auto;
}

.newsItem__info {
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start;
  justify-content: space-between;
}

.newsItem__datetime {
  font-size: 0.75rem;
  font-weight: 500;
  margin-bottom: 10px;
}

.newsItem__date {
  color: #5c5c5c;
}

.newsItem__time {
  position: relative;
  color: #5c5c5c;
  margin-left: calc(1em + 1px);
}

.newsItem__time::before {
  position: absolute;
  left: calc(-.5em - 1px);
  top: 0;
  width: 1px;
  height: 100%;
  background-color: #5c5c5c;
  content: '';
}

.newsItem__title {
  color: #202122;
  flex-grow: 1;
}

.newsItem--transperant {
  background: transparent;
}

.newsItem--padding {
  padding-left: 0;
  padding-right: 0;
}

.newsItem__video {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.7);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56 56'%3E%3Ctitle%3Eplay-icon%3C/title%3E%3Cg id='play-icon' fill='%23202122'%3E%3Cpath id='Triangle' d='M37,28.5l-17,10v-20Z'/%3E%3C/g%3E%3C/svg%3E");
  width: 50px;
  height: 50px;
}

.newsItem__link {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: none;
  border: none;
  overflow: hidden;
  text-indent: 200%;
  white-space: nowrap;
  z-index: 11;
}

.newsDetail {
  background: #FFF;
  background-repeat: no-repeat;
  background-position: top center, bottom center;
  background-size: auto;
  padding-bottom: 64px;
}

.newsDetail__inner {
  padding: 32px 0;
  background-color: transparent;
}

.newsDetail__header {
  padding-bottom: 40px;
}

.newsDetail__title {
  font-size: 1.75rem;
  font-weight: bold;
  color: #0058ff;
}

.newsDetail__date {
  font-weight: normal;
  margin-bottom: 1.5rem;
}

.newsDetail__mrLink {
  font-size: 1rem;
  font-weight: 500;
  display: block;
}

.newsDetail__mrLink:not(:last-child) {
  margin-bottom: 24px;
}

.newsDetail__mrDateTime {
  font-size: 0.6875rem;
  font-weight: 500;
  color: #5c5c5c;
  margin-top: 4px;
}

.newsDetail__mr {
  padding: 16px;
  background-color: #f7f7f7;
  position: sticky;
  top: 16px;
}

.newsDetail__mrTitle {
  margin-bottom: 1.5rem;
  color: #0058ff;
}

.newsDetail__visual {
  width: 100%;
  position: relative;
  margin-bottom: 24px;
}

.newsDetail__visual video {
  position: relative;
  width: 100%;
  height: auto;
}

.newsDetail #player {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
  height: 100%;
}

.newsDetail__image {
  max-width: 100%;
  width: 100%;
  height: auto;
}

.newsDetail__text {
  margin-bottom: 24px;
}

.newsDetail__text img {
  max-width: 100%;
  width: 100%;
  height: auto;
}

.newsDetail__text--blur {
  position: relative;
  filter: blur(4px);
  margin-bottom: 0;
}

.newsDetail__text--blur::after {
  position: absolute;
  top: -8px;
  right: -8px;
  bottom: -8px;
  left: -8px;
  background: linear-gradient(rgba(255, 255, 255, 0), #FFF);
  content: '';
}

.mainHeader__brand,
.pageFooter__logo {
  display: none;
}
